//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import Document from "@/view/pages/userDetail/Document";
import UserActivity from "@/view/pages/userDetail/UserActivity";
import Permission from "@/view/pages/userDetail/Permissions";
export default {
  name: "UserDetail",
  data: function data() {
    return {
      name: "",
      email: "",
      profile_img: "https://picsum.photos/id/11/500/300",
      userTab: null
    };
  },
  components: {
    Document: Document,
    UserActivity: UserActivity,
    Permission: Permission
  }
};