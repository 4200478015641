//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: "UserDocument",
  data: function data() {
    return {
      documents: [{
        name: "Bthrust",
        folder: true,
        Owner: "me",
        innerfiles: "15 files",
        icon: "media/custom-svg/folder-document.svg"
      }, {
        name: "Bthrust",
        folder: true,
        Owner: "me",
        innerfiles: "15 files",
        icon: "media/custom-svg/folder-document.svg"
      }, {
        name: "Bthrust",
        folder: true,
        Owner: "me",
        innerfiles: "15 files",
        icon: "media/custom-svg/folder-document.svg"
      }, {
        name: "Bthrust",
        folder: true,
        Owner: "me",
        innerfiles: "15 files",
        icon: "media/custom-svg/folder-document.svg"
      }, {
        name: "Bthrust",
        folder: false,
        createDate: "3 day ago",
        icon: "media/custom-svg/doc.svg"
      }, {
        name: "Bthrust",
        folder: false,
        createDate: "3 day ago",
        icon: "media/custom-svg/pdf.svg"
      }, {
        name: "Bthrust",
        folder: false,
        createDate: "3 day ago",
        icon: "media/custom-svg/pdf.svg"
      }, {
        name: "Bthrust",
        folder: false,
        createDate: "3 day ago",
        icon: "media/custom-svg/doc.svg"
      }]
    };
  }
};